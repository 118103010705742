<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableNew'
import { getAction } from '@/command/netTool'
import moment from 'moment'
export default {
  methods: {
    getTimePickerKeys() {
      const { dateTypeQuery } = this.$route.query
      switch (dateTypeQuery) {
        case 0:
          return ['dateGe', 'dateLe']
        case 1:
          return ['dateMonthGe', 'dateMonthLe']
        case 2:
          return ['dateYearStr', 'dateYearStr']
      }
    },
    getHeader() {
      const { timeStr } = this.$route.query
      return [
        {
          type: 'select',
          name: '订单状态',
          key: 'status',
          typeData: [
            {
              name: '全部',
              value: '',
            },
            {
              name: '待使用',
              value: 1,
            },
            {
              name: '已完成',
              value: 5,
            },
          ],
        },
        {
          type: 'rangePicker',
          name: '时间',
          key: 'timeStr',
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '出售商品',
          dataIndex: 'productName',
        },
        {
          title: '数量',
          dataIndex: 'productQuantity',
        },
        {
          title: '订单金额',
          dataIndex: 'totalAmount',
          customRender: (text, records) => {
            let newText = text
            if (records.refundAmount) {
              newText = newText + `(退款:${records.refundAmount})`
            }
            return newText
          },
        },
        {
          title: '购买人',
          dataIndex: 'buyName',
        },
        {
          title: '手机号',
          dataIndex: 'buyPhone',
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
        },
        // {
        //   title: '核销时间',
        //   dataIndex: 'usedTime',
        // },
        {
          title: '订单状态',
          dataIndex: 'status',
          customRender: (data) => {
            const state = ['待支付', '待使用', '待发货', '待签收', '待评价', '已完成', '已关闭'][data]
            return <div>{state}</div>
          },
        },
      ]
    },
    onHeaderCallBack(data) {
      if (data.timeStr) {
        const dateGe = moment(data.timeStr[0]).format('YYYY-MM-DD')
        const dateLe = moment(data.timeStr[1]).format('YYYY-MM-DD')
        return { dateGe, dateLe, dateTypeQuery: '0' }
      } else {
        return {}
      }
    },
  },
  render() {
    const { dateLe, dateGe, dateTypeQuery, productId } = this.$route.query
    return (
      <TemplateHeaderTableNew
        url={`/reportForm/getOrderPage`}
        pageParams={{ productId }}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        onHeaderCallBack={this.onHeaderCallBack}
      />
    )
  },
}
</script>
